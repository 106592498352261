@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900");

@font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/SFProDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/SFProDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: lighter;
  src: url("/fonts/SFProDisplay-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  font-style: normal;
  font-weight: bolder;
  src: url("/fonts/SFProText-Bold.ttf") format("truetype");
}

* {
  outline: none !important;
}

body {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", "SFPro";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace, "SFPro";
  margin: 0;
  font-family: "SFPro";
  font-size: 1rem;
  font-weight: lighter;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: "SFPro";
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: "SFPro";
}

#root {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #121212;
}
